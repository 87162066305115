export const environment = {
    employersApi: "https://employer.simonbe.workers.dev",
    jobsearchApi: "https://jobsearch.api.jobtechdev.se",
    jobsearchApiKey: "apa2",
    occupationTypeDropdown: "ssyk4", // avail: occupation-name, ssyk4
    production: true,
    employerApiAccessPassword: undefined,
    tlsSubDomainName: "jobbometern-tsl",
    forceBrandingMode: "Tsl"
};
