<div class="app-footer">

    <div fxLayout="row" fxLayoutAlign="end center">
        <div *ngIf="brandMode === brandModeEnum.Tsl" class="tsl-footer-container" fxLayout="row" fxLayoutAlign="end center">
            <p class="footer-text">Ett samarbetsprojekt mellan Arbetsförmedlingens JobTech Development och Trygghetsfonden TSL</p>
            <a target="_blank" href="https://tsl.se" title="Till tsl.se">
                <img src="../../assets/tsl-logo.svg" class="tsl-logo">
            </a>
        </div>
        <a target="_blank" href="https://www.jobtechdev.se" title="Till jobtechdev.se">
            <img class="footer-logo" src="../../assets/jobtechdev_black.png">
        </a>

    </div>
</div>
  