<nav
  class="navbar navbar-mobile navbar-expand-lg navbar-dark bg-color-1 navbar-static-top"
>
  <div class="regular-navbar">
    <a class="navbar-brand" href="/">
      <span class="header-lead">Jobbometern</span>
    </a>

  </div>


</nav>
